@if (totalRecords > 5) {
  <div class="flex items-center gap-2 bg-white justify-between h-8">
    <div class="flex items-center gap-2">
      <span>{{ 'common.items-per-page' | translate }}</span>
      <p-dropdown
        [ngModel]="pagination.size"
        (onChange)="onRowsChange($event)"
        [options]="options"
        appendTo="body"
        dropdownIcon="icon-wl-arrow-drop-down ml-5 !text-neutral-600"></p-dropdown>
    </div>
    <p-paginator
      (onPageChange)="onPageChange($event)"
      [first]="pagination.size * pagination.page"
      [rows]="pagination.size"
      [totalRecords]="totalRecords"
      [showFirstLastIcon]="false"></p-paginator>

    <div class="flex items-center gap-2">
      <span>{{ 'common.jump-to' | translate }}</span>
      <input type="text" pInputText class="h-8 w-12 text-center" [(ngModel)]="jumpTo" (keyup.enter)="onJumpPage()" />
    </div>
  </div>
}
